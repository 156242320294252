import axios, { AxiosRequestConfig } from 'axios'

import { IRecord, IReqData, TShopValues } from './interface'
import './__mock__'

/** 获取商店列表 */
export const apiGetRecords = async (params: IReqData, signal?: AxiosRequestConfig['signal']): Promise<IRecord[]> => {
  return axios.get('/linkhub_oms/shop/list', { params, signal }).then(({ $data }) => $data || [])
}

/** 新增商店授权之前 */
export const apiBeforeAuthorization = async (reqData: TShopValues): Promise<{ sysShopId: string }> => {
  return axios.post('/linkhub_oms/shop', reqData).then(res => res.$data)
}

/** 授权之后 */
export const apiAfterAuthorization = async (reqData: {
  sysShopId: string
  code: string
  shopUrl: string
  timestamp: number
}): Promise<void> => {
  await axios.post('/linkhub_oms/shop/access_token', reqData)
}

/** 编辑商店  */
export const apiEditShop = async (
  reqData: Pick<IRecord, 'sysShopId'> & Pick<TShopValues, 'shopName' | 'logoUrl'>,
): Promise<void> => {
  await axios.put('/linkhub_oms/shop', reqData)
}

/** 获取物流查询网址配置 */
export const apiGetLogisticsUrlConfig = async (
  sysShopId: string,
): Promise<{
  /** 1-全程运单号(默认); 2-尾程追踪号; */
  transportType: 1 | 2
  /** 查询网址 */
  queryUrl: string
}> => {
  return axios.get('/linkhub_oms/logistic/logistic_url_detail', { params: { sysShopId } }).then(res => ({
    transportType: res.$data?.transportType ?? 1,
    queryUrl: res.$data?.queryUrl ?? '',
  }))
}

/** 设置物流查询网址配置 */
export const apiSetLogisticsUrlConfig = async (
  reqData: GetPromiseValue<typeof apiGetLogisticsUrlConfig> & { sysShopId: string },
) => {
  await axios.post('/linkhub_oms/logistic/set_logistic_url', reqData)
}

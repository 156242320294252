import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLatest, useRequest } from 'ahooks'
import { Form, Input, message, Modal, Popconfirm, Popover, Select, Space, Tag } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { Loading } from '@/components/Loading'
import { formAutoTrimOnBlur } from '@/utils/utils'
import { apiGetLogisticsUrlConfig, apiSetLogisticsUrlConfig } from './apis'
import { IRecord } from './interface'
import { popupSlot } from './utils'

interface ISetLogisticsUrlConfigModalProps {
  record: IRecord
  onSuccess?: () => void
}

const updatePopup = popupSlot.insert(null)

export const SetLogisticsUrlConfigModal = Object.assign(
  ({ record, onSuccess, destroy }: ISetLogisticsUrlConfigModalProps & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)

    const { sysShopId } = record
    const { loading, data } = useRequest(() => apiGetLogisticsUrlConfig(record.sysShopId))
    const [saveLoading, setSaveLoading] = useState(false)

    const initialValues: GetPromiseValue<typeof apiGetLogisticsUrlConfig> = {
      transportType: 1,
      queryUrl: '',
    }
    const [form] = Form.useForm<typeof initialValues>()
    useEffect(() => {
      if (!data) return
      form.setFieldsValue(data)
    }, [data, form])

    const latestRef = useLatest({ onSuccess })

    return (
      <Modal
        title={t('ShopList.wu-liu-cha-xun-wang-zhi-she-zhi')}
        width={700}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
        cancelButtonProps={{ disabled: saveLoading }}
        okButtonProps={{ loading: saveLoading }}
        onOk={async () => {
          if (!data) return
          let values = await form.validateFields()
          values = form.getFieldsValue(true)
          try {
            setSaveLoading(true)
            await apiSetLogisticsUrlConfig({ ...values, sysShopId })
            message.success(t('2-common.cao-zuo-cheng-gong'))
            onHide()
            latestRef.current.onSuccess?.()
          } finally {
            setSaveLoading(false)
          }
        }}
      >
        <div style={{ minHeight: !data ? 200 : undefined, padding: '24px 0 12px' }}>
          {loading && <Loading />}
          {!!data && (
            <Form
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 24 - 6 - 1 }}
              initialValues={initialValues}
              onBlur={e => formAutoTrimOnBlur({ event: e, form, includeField: ['queryUrl'] })}
            >
              <Form.Item label={t('ShopList.dian-pu-ming-cheng')}>
                <>{record.shopName}</>
              </Form.Item>
              <Form.Item label={t('ShopList.yun-shu-lei-xing')} name="transportType" rules={[{ required: true }]}>
                <Select
                  options={[
                    { value: 1, label: t('ShopList.quan-cheng-yun-dan-hao') },
                    { value: 2, label: t('ShopList.wei-cheng-zhui-zong-hao') },
                  ]}
                  placeholder={t('2-common.qing-xuan-ze')}
                />
              </Form.Item>
              <Form.Item
                required
                label={
                  <>
                    {t('ShopList.wu-liu-cha-xun-wang-zhi')}
                    <Popover
                      overlayStyle={{ width: 400 }}
                      content={
                        <Space direction="vertical">
                          <div>{t('ShopList.wu-liu-cha-xun-wang-zhi-tip')}</div>
                          <div>
                            <div>{t('ShopList.ti-huan-qian')}: https://17track.com?nums=LJ000212112CN</div>
                            <div>{t('ShopList.ti-huan-hou')}: https://17track.com?nums=[tracknumber]</div>
                          </div>
                        </Space>
                      }
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 4 }} />
                    </Popover>
                  </>
                }
                extra={
                  <>
                    <div style={{ marginTop: 12, marginBottom: 12 }}>
                      {t('ShopList.chang-yong-wu-liu-cha-xun-wang-zhi-dian-ji-ke-tian-chong')}:
                    </div>
                    <Space wrap>
                      {[
                        {
                          name: 'track123',
                          url: 'https://www.track123.com/track?code&trackNos=[tracknumber]',
                        },
                        { name: 'afterShip', url: 'https://www.aftership.com/track?t=[tracknumber]' },
                        { name: '17track', url: 'https://17track.com?nums=[tracknumber]' },
                      ].map(({ name, url }) => (
                        <Popconfirm
                          key={name}
                          overlayStyle={{ maxWidth: 300 }}
                          placement="bottom"
                          title={t('ShopList.que-ren-yao-tian-chong-yi-xia-wang-zhi-ma')}
                          description={url}
                          onConfirm={() => {
                            form.setFieldValue('queryUrl', url)
                            form.validateFields()
                          }}
                        >
                          <Tag style={{ cursor: 'pointer' }}>{name}</Tag>
                        </Popconfirm>
                      ))}
                    </Space>
                  </>
                }
              >
                <Form.Item
                  label={t('ShopList.wu-liu-cha-xun-wang-zhi')}
                  noStyle
                  name="queryUrl"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} placeholder={t('2-common.qing-shu-ru')} />
                </Form.Item>
              </Form.Item>
            </Form>
          )}
        </div>
      </Modal>
    )
  },
  {
    open: (props: ISetLogisticsUrlConfigModalProps) => {
      updatePopup(<SetLogisticsUrlConfigModal {...props} destroy={() => updatePopup(null)} />)
    },
  },
)

import { CSSProperties } from 'react'

import { PLAT_FORM_LOGO_MAP, PLAT_FORM_NAME_MAP } from '../consts/consts'
import type { IShopRecord } from '../ShopList'

/** 平台显示 */
export const PlatFormShow = ({
  platForm,
  style,
  className,
}: Pick<IShopRecord, 'platForm'> & { style?: CSSProperties; className?: string }) => {
  return (
    <span style={style} className={className}>
      {PLAT_FORM_LOGO_MAP[platForm] || PLAT_FORM_NAME_MAP[platForm] || platForm}
    </span>
  )
}

import { apiBeforeAuthorization } from './apis'
import { IRecord, TShopValues } from './interface'

const authorization = ({ sysShopId, url }: Pick<IRecord, 'platForm' | 'sysShopId' | 'url'>) => {
  const cb = `${location.origin}${import.meta.env.VITE_APP_BASE_NAME}/biz/shop/authorizationResult` // 不可带参

  let search = `?client_id=a2bbca604e4eb40f1cbedc711e0e6b08`
  search += `&scope=read_orders,write_orders,read_inventory,write_inventory,read_products,write_products,read_locations,read_fulfillments,write_fulfillments,read_merchant_managed_fulfillment_orders,read_third_party_fulfillment_orders,write_merchant_managed_fulfillment_orders,write_third_party_fulfillment_orders,write_assigned_fulfillment_orders,read_returns`
  search += `&redirect_uri=${encodeURIComponent(cb)}&state=${sysShopId}`
  search += `&grant_options[]`

  location.href = `${url}/admin/oauth/authorize${search}` // 跳转
}

export const handleAuthorization = async (reqData: TShopValues): Promise<void> => {
  const { sysShopId } = await apiBeforeAuthorization(reqData)
  authorization({
    platForm: reqData.platForm,
    sysShopId,
    url: reqData.url,
  })
}

export const handleReAuthorization = (props: Pick<IRecord, 'platForm' | 'sysShopId' | 'url'>) => {
  authorization(props)
}
